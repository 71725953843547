<template>
  <section
    class="article-card"
    :class="{ 'article-card--coming-soon': article.isComingSoon }"
    @click="goToArticlePage"
  >
    <div v-if="article.isComingSoon" class="article-card__coming-soon">Coming soon</div>
    <div class="article-card__icon custom-icon mb10" :class="articleIcon"></div>
    <div
      class="article-card__title mb10"
      :class="{ 'article-card__title--coming-soon': article.isComingSoon }"
    >
      {{ $t(`userGuide.title.${article.title}`) }}
    </div>
    <div class="article-card__description">
      {{ $t(`userGuide.mainPage.${article.description}`) }}
    </div>
  </section>
</template>

<script>
import { ARTICLE_COMPONENT_CONFIG } from '@/modules/dashboard/api/user-guide';

export default {
  name: 'ArticleCard',
  props: {
    article: {
      type: Object,
      required: true
    }
  },
  computed: {
    articleIcon() {
      return ARTICLE_COMPONENT_CONFIG[this.article.pageName].icon || 'icon-skinxs-gray';
    }
  },
  methods: {
    goToArticlePage() {
      const { pageName } = this.article;

      if (!pageName || !ARTICLE_COMPONENT_CONFIG[pageName].name) {
        return;
      }

      this.$router.push({
        name: 'ArticleSection',
        params: {
          articleName: pageName
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/helpers/colors';
@import '../../../../assets/css/common/icons';

.article-card {
  position: relative;
  width: 487px;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 25px;

  border-bottom: 1px solid $concrete;

  &:nth-child(odd):not(:last-child) {
    margin-right: 5px;
  }

  &:hover {
    background-color: $white;
    cursor: pointer;
  }

  &--coming-soon:hover {
    background-color: $background-darker;
  }

  &__coming-soon {
    position: absolute;
    top: 10px;
    left: 10px;

    padding: 4px 9px 3px 10px;
    background: $hovered-button-color;
    border-radius: 10px;

    font-weight: 500;
    font-size: 7px;
    line-height: 8px;
    letter-spacing: 0.2em;
    text-transform: uppercase;

    color: $text-color;
  }

  &__title {
    font-size: 26px;
    font-weight: 600;
    color: $text-color;
    text-align: center;

    &--coming-soon {
      color: $grey-color;
    }
  }

  &__description {
    max-width: 100%;

    font-size: 14px;
    line-height: 20px;

    color: $grey-color;
    text-align: center;
  }
}

.article-card:hover {
  .icon-skinxs-gray {
    background-image: url(~@/assets/images/dashboard/icons/skinxs-orange-icon.svg);
  }

  .icon-person {
    background-image: url(~@/assets/images/dashboard/icons/person-icon-orange.svg);
  }

  .icon-patients {
    background-image: url(~@/assets/images/dashboard/icons/patients-icon-orange.svg);
  }

  .icon-dashboard {
    background-image: url(~@/assets/images/dashboard/icons/dashboard-icon-orange.svg);
  }

  .icon-inviting-patients {
    background-image: url(~@/assets/images/dashboard/icons/inviting-patients-icon-orange.svg);
  }

  .icon-questionnaire {
    background-image: url(~@/assets/images/dashboard/icons/questionnaire-icon-orange.svg);
  }

  .icon-photo-analysis-article {
    background-image: url(~@/assets/images/dashboard/icons/photo-analysis-article-icon-orange.svg);
  }

  .icon-skin-diagnostic {
    background-image: url(~@/assets/images/dashboard/icons/skin-diagnostic-icon-orange.svg);
  }

  .icon-treatment {
    background-image: url(~@/assets/images/dashboard/icons/treatment-icon-orange.svg);
  }

  .icon-loyalty-program-article {
    background-image: url(~@/assets/images/dashboard/icons/loyalty-program-article-icon-orange.svg);
  }

  .icon-quick-label-print {
    background-image: url(~@/assets/images/dashboard/icons/quick-label-print-icon-orange.svg);
  }

  .icon-message-templates {
    background-image: url(~@/assets/images/dashboard/icons/message-templates-icon-orange.svg);
  }

  .icon-notifications {
    background-image: url(~@/assets/images/dashboard/icons/notifications-icon-orange.svg);
  }

  .icon-glossary {
    background-image: url(~@/assets/images/dashboard/icons/glossary-icon-orange.svg);
  }

  .icon-basket {
    background-image: url(~@/assets/images/dashboard/icons/basket-icon-orange.svg);
  }

  .icon-microneedling-user-guide {
    background-image: url(~@/assets/images/dashboard/icons/microneedling/microneedling-user-guide-orange.svg);
  }

  .icon-price-settings {
    background-image: url(~@/assets/images/dashboard/icons/price-settings-icon-orange.svg);
  }

  .icon-treatments-directory {
    background-image: url(~@/assets/images/dashboard/icons/treatments-directory-icon-orange.svg);
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .article-card {
    width: 49.65%;
  }
}

@media (max-width: 767px) {
  .article-card {
    width: 100%;

    &:nth-child(odd):not(:last-child) {
      margin-right: 0;
    }
  }
}
</style>
