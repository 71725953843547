<template>
  <section>
    <button class="section__tutorial-button rounded-button-orange" @click="openTutorialModal">
      {{ $t('dashboard.label.tutorial') }}
    </button>
    <section class="section__articles-list">
      <article-card v-for="article in $options.articles" :key="article.title" :article="article" />
    </section>

    <tutorial-modal />
  </section>
</template>

<script>
import ArticleCard from '@/modules/dashboard/components/user-guide/ArticleCard';
import TutorialModal from '@/modules/dashboard/components/tutorial/TutorialModal';

import { handleRoutingInfo } from '@/modules/dashboard/utils';

import {
  ARTICLE_COMPONENT_CONFIG,
  USER_GUIDE_ARTICLE_NAME
} from '@/modules/dashboard/api/user-guide';
import { TUTORIAL_ROUTING_INFO } from '@/modules/dashboard/api/constants';

const ARTICLES = [
  {
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.ABOUT_SKINXS].title,
    description: 'skinxsDescription',
    pageName: USER_GUIDE_ARTICLE_NAME.ABOUT_SKINXS
  },
  {
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.PERSONAL_ACCOUNT].title,
    description: 'personalAccountDescriprion',
    pageName: USER_GUIDE_ARTICLE_NAME.PERSONAL_ACCOUNT
  },
  {
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.DASHBOARD].title,
    description: 'dashboardDescription',
    pageName: USER_GUIDE_ARTICLE_NAME.DASHBOARD
  },
  {
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.PATIENT].title,
    description: 'patientDescription',
    pageName: USER_GUIDE_ARTICLE_NAME.PATIENT
  },
  {
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.INVITING_PATIENTS].title,
    description: 'invitingPatientsDescription',
    pageName: USER_GUIDE_ARTICLE_NAME.INVITING_PATIENTS
  },
  {
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.QUESTIONNAIRE].title,
    description: 'questionnaireDescription',
    pageName: USER_GUIDE_ARTICLE_NAME.QUESTIONNAIRE
  },
  {
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.PHOTO_ANALYSIS].title,
    description: 'photoAnalysisSectionDescription',
    pageName: USER_GUIDE_ARTICLE_NAME.PHOTO_ANALYSIS
  },
  {
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.SKIN_DIAGNOSTIC].title,
    description: 'skinDiagnosticsDescription',
    pageName: USER_GUIDE_ARTICLE_NAME.SKIN_DIAGNOSTIC
  },
  {
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.TREATMENT].title,
    description: 'treatmentDescription',
    pageName: USER_GUIDE_ARTICLE_NAME.TREATMENT
  },
  {
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.TREATMENTS_DIRECTORY].title,
    description: 'treatmentsDirectoryDescription',
    pageName: USER_GUIDE_ARTICLE_NAME.TREATMENTS_DIRECTORY
  },
  {
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.CONCIERGE_SERVICE].title,
    description: 'onlineConciergeServiceDescription',
    pageName: USER_GUIDE_ARTICLE_NAME.CONCIERGE_SERVICE
  },
  {
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.PRICE_SETTINGS].title,
    description: 'priceSettingsDescription',
    pageName: USER_GUIDE_ARTICLE_NAME.PRICE_SETTINGS
  },
  {
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.MICRONEEDLING].title,
    description: 'microneedlingDescription',
    pageName: USER_GUIDE_ARTICLE_NAME.MICRONEEDLING
  },
  {
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.LOYALTY_PROGRAM].title,
    description: 'loyaltyProgramDescription',
    pageName: USER_GUIDE_ARTICLE_NAME.LOYALTY_PROGRAM
  },
  {
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.QUICK_LABEL_PRINT].title,
    description: 'quickLabelPrintDescription',
    pageName: USER_GUIDE_ARTICLE_NAME.QUICK_LABEL_PRINT
  },
  {
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.MESSAGE_TEMPLATES].title,
    description: 'messageTemplatesDescription',
    pageName: USER_GUIDE_ARTICLE_NAME.MESSAGE_TEMPLATES
  },
  {
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.NOTIFICATIONS].title,
    description: 'notificationsDescription',
    pageName: USER_GUIDE_ARTICLE_NAME.NOTIFICATIONS
  },
  {
    title: ARTICLE_COMPONENT_CONFIG[USER_GUIDE_ARTICLE_NAME.GLOSSARY].title,
    description: 'glossaryDescription',
    pageName: USER_GUIDE_ARTICLE_NAME.GLOSSARY
  }
];

export default {
  name: 'ArticlesSelectionSection',
  components: { TutorialModal, ArticleCard },
  articles: ARTICLES,
  methods: {
    openTutorialModal() {
      handleRoutingInfo(TUTORIAL_ROUTING_INFO, this.$router, this.$modal);
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../../../../assets/scss/common/buttons';

.section {
  &__tutorial-button {
    height: 40px;
    display: block;
    margin: 0 auto 40px;

    font-weight: 600;
    color: $text-color;
  }

  &__articles-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
}
</style>
